import {Controller} from "@hotwired/stimulus";
import Modal from "../../components/modal";

/**
 * Обработчик форм переименования метрик в листе.
 */
export default class extends Controller {
    writeMode = false;

    static values = {
        title: String,
        description: String,
        url: String
    }

    static get targets () {
        return [
            'helpLabel',
            'editInputWrapper',
            'editInput',
            'editBtn',
            'saveBtn',
            'cancelBtn'
        ];
    }

    edit() {
        if (this.writeMode) {
            return;
        }

        this.writeMode = true;

        this.helpLabelTarget.classList.add('d-none');
        this.editInputWrapperTarget.classList.remove('d-none');
        this.saveBtnTarget.classList.remove('d-none');
        this.cancelBtnTarget.classList.remove('d-none');
        this.editBtnTarget.classList.add('d-none');

        document.querySelectorAll('.btn-metric-edit').forEach((item) => {
            if (item === this.editBtnTarget) {
                return;
            }

            item.classList.add('d-none');
        });
    }

    async save() {
        const modal = new Modal(
            this.titleValue,
            this.descriptionValue.replace('%s', this.editInputTarget.value),
            {
                type: 'confirm',
                yesLabel: 'Переименовать',
                noLabel: 'Отмена',
                callback: async () => {
                    const value = this.editInputTarget.value;

                    let response = await fetch(this.urlValue, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify({
                            value: value
                        })
                    });

                    if (response.status === 200) {
                        Turbo.visit(document.location);
                    } else {
                        (new Modal('Ошибка!', 'При сохранении названия произошла ошибка.', { type: "alert"})).show();
                    }
                }
            }
        );

        modal.show();
    }

    cancel() {
        if (!this.writeMode) {
            return;
        }

        this.writeMode = false;

        this.helpLabelTarget.classList.remove('d-none');
        this.editInputWrapperTarget.classList.add('d-none');
        this.saveBtnTarget.classList.add('d-none');
        this.cancelBtnTarget.classList.add('d-none');
        this.editBtnTarget.classList.remove('d-none');

        document.querySelectorAll('.btn-metric-edit').forEach((item) => {
            if (item === this.editBtnTarget) {
                return;
            }

            item.classList.remove('d-none');
        });
    }
}