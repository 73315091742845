import {Controller} from "@hotwired/stimulus";
import {createCallback, getPluginsDefaults, getRenderDefaults} from "../../components/tomselect2";
import TomSelect from "tom-select";
import {addDeleteLink, addFormToCollection} from "../../components/collection";

/**
 * Обработчик для формы Брифа, раздел "Целевые аудитории".
 */
export default class extends Controller {
    static get targets() {
        return ['list', 'interest', 'socialStatus'];
    }

    selects = [];

    initialize() {
        super.initialize();

        this.interestOptions = {
            create: createCallback('/reference/create/interest'),
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        };

        this.socialStatusOptions = {
            valueField: 'id',
            labelField: 'title',
            create: createCallback('/reference/create/social_status'),
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        };
    }

    connect() {
        this.listTarget.dataset["index"] = this.listTarget.querySelectorAll("input").length;

        this.interestTargets.forEach((element) => {
            this.selects.push(new TomSelect(element, this.interestOptions));
        });

        this.socialStatusTargets.forEach((element) => {
            this.selects.push(new TomSelect(element, this.socialStatusOptions));
        });

        this.listTarget.querySelectorAll('li').forEach((item) => {
            addDeleteLink(item);
        });
    }

    disconnect() {
        for (const i in this.selects) {
            this.selects[i].destroy();
        }

        this.selects = [];
    }

    addItem() {
        const index = addFormToCollection(this.listTarget);

        this.selects.push(new TomSelect('#brief_targetAudiences_' + index + '_interests', this.interestOptions));
        this.selects.push(new TomSelect('#brief_targetAudiences_' + index + '_socialStatuses', this.socialStatusOptions));
    }
}