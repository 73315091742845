import {Controller} from "@hotwired/stimulus";
import Modal from "../../components/modal";

/**
 * Обработчик удаления и копирования метрик на странице редактирования листа.
 */
export default class extends Controller {
    static values = {
        deleteMetricsUrl: String,
        csrfToken: String,
    }

    static get targets () {
        return ['selectAllButton', 'copyButton'];
    }

    connect() {
        const _this = this;
        let deleteMetricsUrl = this.deleteMetricsUrlValue;
        let token = this.csrfTokenValue;

        $('[data-name="metrics"]').on('click', function () {
            _this.handleDeleteButton();
        });

        const hasCheckboxes = !!$('.del_metric_checkbox').length;

        if (hasCheckboxes) {
            this.selectAllButtonTarget.classList.remove('d-none');
        }

        $('#del_metrics').on('click', function () {
            let metricNames = [];

            $('.del_metric_checkbox').each(function () {
                if ($(this).prop('checked')) {
                    metricNames.push($(this).attr('data-name'));
                }
            });

            const confirmModal = new Modal('Подтверждение удаления', 'Вы действительно хотите удалить метрики: "'+metricNames.join(', ')+'"?', {
                yesLabel: 'Удалить',
                callback: async function () {
                    let metrics = [];

                    $('.del_metric_checkbox').each(function () {
                        if ($(this).prop('checked')) {
                            metrics.push($(this).val());
                        }
                    });

                    $.ajax({
                        method: 'POST',
                        url: deleteMetricsUrl,
                        data: { '_token': token, 'metrics': metrics },
                        success: function () {
                            location.reload();
                        },
                        error: function (request, status, error) {
                            if (status !== 200) {
                                (new Modal('Ошибка!', error, {type: "alert"})).show();
                            }
                        }
                    });
                }
            });

            confirmModal.show();
        });
    }

    selectAll() {
        let hasNotChecked = false;

        $('.del_metric_checkbox').each(function () {
            if (!$(this).prop('checked')) {
                hasNotChecked = true;
            }
        });

        if (hasNotChecked) {
            $('.del_metric_checkbox').each(function () {
                $(this).prop('checked', true);
            });
        } else {
            $('.del_metric_checkbox').each(function () {
                $(this).prop('checked', false);
            });
        }

        this.handleDeleteButton();
    }

    handleDeleteButton() {
        let checked = false;

        $('.del_metric_checkbox').each(function () {
            if ($(this).prop('checked')) {
                checked = true;
            }
        });

        if (checked) {
            $('#del_metrics').removeClass('d-none');
            this.copyButtonTarget.classList.remove('d-none');
        } else {
            $('#del_metrics').addClass('d-none');
            this.copyButtonTarget.classList.add('d-none');
        }
    }
}