import { Controller } from "@hotwired/stimulus";

/**
 * Обработчик клика по всем чекбоксам в дереве указанного элемента страницы.
 */
export default class extends Controller {
   static values = {
      container: String
   }

   connect() {
      this.element.addEventListener('click', () => {
         document.querySelectorAll(this.containerValue + ' input[type=checkbox]').forEach((item) => {
            item.checked = this.element.checked;
         });
      })
   }
}