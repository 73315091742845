import {Controller} from "@hotwired/stimulus";
import {addDeleteLink, addFormToCollection} from "../../components/collection";

/**
 * Обработчик для формы Брифа, раздел "Доступы к аналитике".
 */
export default class extends Controller {
    static get targets() {
        return ['list'];
    }

    connect() {
        this.listTarget.dataset["index"] = this.listTarget.querySelectorAll("input").length;

        this.listTarget.querySelectorAll('li').forEach((item) => {
            addDeleteLink(item);
        });
    }

    addItem() {
        addFormToCollection(this.listTarget);
    }
}