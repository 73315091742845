import { Controller } from "@hotwired/stimulus";
import {getPluginsDefaults, getRenderDefaults, onItemAddDefault} from "../../components/tomselect2";
import TomSelect from "tom-select";

/**
 * Подключение TomSelect к элементам страницы.
 */
export default class extends Controller {
    select;

    connect() {
        this.select = new TomSelect(this.element, {
            render: getRenderDefaults(),
            onItemAdd: onItemAddDefault(),
            plugins: getPluginsDefaults(),
        });
    }

    disconnect() {
        this.select.destroy();
    }
}