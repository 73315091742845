import {Controller} from "@hotwired/stimulus";
import Modal from "../../../components/modal";

/**
 * Установка статус Прочитано в Уведомлениях.
 */
export default class extends Controller {
    async mark(event) {
        event.preventDefault();

        const { id } = event.params;

        let response = await fetch('/profile/notification/mark-read/'+id, {
            method: 'POST',
        });

        if (response.status === 200) {
            document.location.reload();
        } else {
            const text = await response.text();
            (new Modal('Ошибка!', text, { type: "alert"})).show();
        }
    }
}