import {Controller} from "@hotwired/stimulus";
import {addDeleteLink, addFormToCollection} from "../../components/collection";

/**
 * Обработчик формы редактирования Клиента (не используется).
 */
export default class extends Controller {
    static get targets() {
        return ['contacts'];
    }

    connect() {
        this.contactsTarget.dataset["index"] = this.contactsTarget.querySelectorAll("input").length;

        this.contactsTarget.querySelectorAll('li').forEach((item) => {
            addDeleteLink(item);
        });
    }

    addItem() {
        addFormToCollection(this.contactsTarget);
    }
}