import { Controller } from '@hotwired/stimulus';
import {addDeleteLink, addFormToCollection, clearCollection} from "../../components/collection";
import TomSelect from "tom-select";
import {getPluginsDefaults, getRenderDefaults} from "../../components/tomselect2";

/**
 * Обработчик формы редактирования комиссии Медиаплана.
 */
export default class extends Controller {
    static targets = ["expressionContainer", "template", "type"]

    connect() {
        this.expressionContainerTarget.querySelectorAll('li').forEach((item) => {
            addDeleteLink(item);
        });

        this.template = new TomSelect(this.templateTarget, {
            persist: false,
            create: false,
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
            onChange: () => {
                const commissionId = parseInt(this.template.getValue());

                if (!commissionId) {
                    return;
                }

                const option = this.templateTarget.querySelector('[value="'+commissionId+'"]');
                const expression = option.getAttribute('data-expression');
                const type = option.getAttribute('data-type');

                const data = JSON.parse(expression);

                clearCollection(this.expressionContainerTarget);

                for (const i in data) {
                    const index = addFormToCollection(this.expressionContainerTarget);
                    const input = document.querySelector('#media_plan_commission_data_expression_'+index);
                    input.value = data[i];
                }

                this.type.setValue(type);
            }
        })

        this.type = new TomSelect(this.typeTarget, {
            persist: false,
            create: false,
            render: getRenderDefaults(),
            plugins: getPluginsDefaults(),
        });
    }

    disconnect() {
        this.template.destroy();
        this.type.destroy();
    }

    addItem() {
        addFormToCollection(this.expressionContainerTarget);
    }
}