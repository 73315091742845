/**
 * Создает/удаляет прелоудер на странице (на весь экран).
 */
export default class {
    static create() {
        const preloader = document.querySelector('.fullscreen-loading');

        if (!preloader) {
            const preloader = document.createElement('div');
            preloader.classList.add('fullscreen-loading');
            document.body.append(preloader);
        }
    }

    static remove() {
        const preloader = document.querySelector('.fullscreen-loading');

        if (preloader) {
            preloader.remove();
        }
    }
}