import {Controller} from "@hotwired/stimulus";

/**
 * Обработчик нажатия клавиши Enter в формах поиска по названию.
 */
export default class extends Controller
{
    static get targets() {
        return ['input'];
    }

    connect() {
        let input = $(this.inputTarget);

        input.keyup(function(event) {
            if (event.keyCode === 13) {
                let url = new URL(window.location.href);

                url.searchParams.set(input.attr('name'), input.val());

                Turbo.visit(url.href);
            }
        });
    }
}