import Cookies from "js-cookie";
import Modal from "../modal";

/**
 * Обработчик состояния масштабирования листа. Хранит состояние в Cookie.
 *
 * @param sheetId идентификатор листа
 */
export function sheetZoomUpdate (sheetId) {
    let zoomWrap = document.getElementById('luckysheet-zoom-content'),
        luckysheetZoom = Cookies.get(sheetId + '_zoom');

    zoomWrap.addEventListener(
        "click",
        () => {
            setTimeout(() => {
                if (luckysheet.getconfig().sheetViewZoom) {
                    Cookies.set(sheetId + '_zoom', luckysheet.getconfig().sheetViewZoom.viewNormalZoomScale);
                }
            }, 250)
        }
    );

    if (luckysheetZoom) {
        luckysheet.setSheetZoom(luckysheetZoom);
    }
}

/**
 * Обработчик состояния прокрутки листа. Хранит состояние в Cookie.
 *
 * @param sheetId идентификатор листа
 */
export function sheetScrollUpdate (sheetId) {
    let listWrap = document.getElementById('luckysheet-cell-main'),
        luckysheetLeft = Cookies.get(sheetId + '_left'),
        luckysheetTop = Cookies.get(sheetId + '_top');

    listWrap.addEventListener(
        "scroll",
        () => {
            let scrollLeft = $("#luckysheet-scrollbar-x").scrollLeft(),
                scrollTop = $("#luckysheet-scrollbar-y").scrollTop();

            Cookies.set(sheetId + '_left', scrollLeft);
            Cookies.set(sheetId + '_top', scrollTop);
        }
    );

    if (luckysheetLeft) {
        luckysheet.scroll({
            scrollLeft: luckysheetLeft
        });
    }

    if (luckysheetTop) {
        luckysheet.scroll({
            scrollTop: luckysheetTop
        });
    }
}

/**
 * Отправляет конфигурацию листа Тотала.
 *
 * @param config конфигурация (размеры строк и столбцов, с группировкой по листам)
 * @param template идентификатор шаблона
 * @param sizeUpdateUrl адрес обработчика
 * @param csrfToken CSRF-токен
 */
export function totalSizeUpdate (config, template, sizeUpdateUrl, csrfToken) {
    $.ajax({
        method: 'POST',
        url: sizeUpdateUrl,
        data: {
            'csrfToken': csrfToken,
            'config': config,
            'template': template,
        },
        success: function () {
            return true;
        },
        error: function (request, status, error) {
            if (status !== 200) {
                (new Modal('Ошибка!', error, {type: "alert"})).show();
            }
        }
    });
}