import { Controller } from "@hotwired/stimulus";
import {Datepicker} from "vanillajs-datepicker";

/**
 * Подключение Datepicker к элементам.
 */
export default class extends Controller {
    datePicker;

    connect() {
        this.datePicker = new Datepicker(this.element, {
            buttonClass: 'btn',
            format: 'dd.mm.yyyy',
            clearBtn: true,
            todayBtn: true,
            language: 'ru'
        });
    }

    disconnect() {
        this.datePicker.destroy();
    }
}