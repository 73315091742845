/**
 * Функция добавления копии формы в коллекцию.
 *
 * @param holder объект, содержащий прототип (шаблон формы)
 * @param showDeleteLink показывать ссылку удаления
 * @returns {string} номер копии формы
 */
export function addFormToCollection(holder, showDeleteLink = true) {
    const prototype = holder.dataset['prototype'];
    const index = holder.dataset['index'];
    const newForm = prototype.replace(/__name__/g, index);

    holder.dataset['index']++;

    const item = document.createElement('li');
    item.innerHTML = newForm;

    if (showDeleteLink) {
        addDeleteLink(item);
    }

    holder.append(item)

    return index;
}

/**
 * Очистка коллекции.
 *
 * @param holder
 */
export function clearCollection(holder) {
    holder.innerHTML = "";
}

/**
 * Добавление ссылки Удалить.
 *
 * @param item
 */
export function addDeleteLink(item) {
    const removeFormButton = document.createElement('button')
    removeFormButton.classList.add('btn');
    removeFormButton.classList.add('btn-danger');
    removeFormButton.innerText = 'Удалить';
    item.append(removeFormButton);

    removeFormButton.addEventListener('click', (e) => {
        e.preventDefault()
        item.remove();
    });
}