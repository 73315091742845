import {Controller} from "@hotwired/stimulus";
import Preloader from '../../components/preloader';
import {Turbo} from "@hotwired/turbo-rails";

/**
 * Обработчик перемещения (сортировки) метрик на странице редактирования листа.
 */
export default class extends Controller {
    oldSortFields;
    oldSortMetrics;

    static values = {
        updateUrl: String,
    }

    static get targets () {
        return ['list'];
    }

    onChange() {
        Preloader.create();

        const sortMetrics = {}, sortFields = {};

        for (const targetListElement of this.listTargets) {
            const itemElements = targetListElement.querySelectorAll('.list-group-item');

            itemElements.forEach((element, i) => {
                element.setAttribute('data-sort', i);

                if (targetListElement.getAttribute('data-name') === 'fields') {
                    sortFields[element.getAttribute('data-id')] = i;
                } else {
                    sortMetrics[element.getAttribute('data-id')] = i;
                }
            });
        }

        let changed = false;

        if (
            (this.oldSortFields && (JSON.stringify(this.oldSortFields) !== JSON.stringify(sortFields))) ||
            (this.oldSortMetrics && (JSON.stringify(this.oldSortMetrics) !== JSON.stringify(sortMetrics)))
        ) {
            changed = true;
        }

        if (changed) {
            $.ajax({
                method: 'POST',
                dataType: 'json',
                url: this.updateUrlValue,
                data: {'sortFields': JSON.stringify(sortFields), 'sortMetrics': JSON.stringify(sortMetrics)},
                success: function (data) {
                    Turbo.visit(data.redirectUrl);
                },
                error: function () {
                    Turbo.visit(data.redirectUrl);
                }
            })
        } else {
            Preloader.remove();
        }
    }

    connect() {
        const oldSortFields = {}, oldSortMetrics = {};

        for (const targetListElement of this.listTargets) {
            const itemElements = targetListElement.querySelectorAll('.list-group-item');

            itemElements.forEach((element, i) => {
                element.setAttribute('data-sort', i);

                if (targetListElement.getAttribute('data-name') === 'fields') {
                    oldSortFields[element.getAttribute('data-id')] = i;
                } else {
                    oldSortMetrics[element.getAttribute('data-id')] = i;
                }
            });

            for (const element of itemElements) {
                element.draggable = true;
            }

            targetListElement.addEventListener('dragstart', (evt) => {
                evt.target.classList.add('selected');
            })

            targetListElement.addEventListener('dragend', (evt) => {
                evt.target.classList.remove('selected');

                this.onChange();
            });

            targetListElement.addEventListener('dragover', (evt) => {
                evt.preventDefault();

                const activeElement = targetListElement.querySelector('.selected');
                const currentElement = evt.target;
                const isMovable = activeElement !== currentElement &&
                    currentElement.classList.contains('list-group-item');

                if (!isMovable) {
                    return;
                }

                const nextElement = (currentElement === activeElement.nextElementSibling) ?
                    currentElement.nextElementSibling :
                    currentElement;

                targetListElement.insertBefore(activeElement, nextElement);
            });

            const getNextElement = (cursorPosition, currentElement) => {
                const currentElementCoord = currentElement.getBoundingClientRect();
                const currentElementCenter = currentElementCoord.y + currentElementCoord.height / 2;
                return (cursorPosition < currentElementCenter) ?
                    currentElement :
                    currentElement.nextElementSibling;
            };

            targetListElement.addEventListener('dragover', (evt) => {
                evt.preventDefault();

                const activeElement = targetListElement.querySelector('.selected');
                const currentElement = evt.target;
                const isMovable = activeElement !== currentElement &&
                    currentElement.classList.contains('list-group-item');

                if (!isMovable) {
                    return;
                }

                const nextElement = getNextElement(evt.clientY, currentElement);

                if (
                    nextElement &&
                    activeElement === nextElement.previousElementSibling ||
                    activeElement === nextElement
                ) {
                    return;
                }

                targetListElement.insertBefore(activeElement, nextElement);
            });
        }

        this.oldSortFields = oldSortFields;
        this.oldSortMetrics = oldSortMetrics;
    }
}