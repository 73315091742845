import { Controller } from "@hotwired/stimulus";
import Modal from "../../components/modal";

/**
 * Универсальный обработчик формы подтверждения действия.
 */
export default class extends Controller {
    static values = {
        url: String,
        title: String,
        message: String,
        yesLabel: String,
        csrfToken: String,
    }

    show(event) {
        event.preventDefault();

        const confirmModal = new Modal(this.titleValue, this.messageValue, {
            yesLabel: this.yesLabelValue,
            callback: async () => {
                let data = {};

                if (this.csrfTokenValue) {
                    data._token = this.csrfTokenValue;
                }

                let response = await fetch(this.urlValue, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams(data).toString()
                });

                if (response.status === 204) {
                    Turbo.visit(document.location);
                } else {
                    if (response.headers.get('Content-Type') === 'application/json') {
                        const json = await response.json();

                        if (json.redirectUrl) {
                            Turbo.visit(json.redirectUrl);
                        }
                    } else {
                        const text = await response.text();
                        (new Modal('Уведомление', text, {type: "alert"})).show();
                    }
                }
            }
        });

        confirmModal.show();
    }
}