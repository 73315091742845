import {Controller} from "@hotwired/stimulus";

/**
 * Запуск Preloader с проверкой статуса по интервалу (не используется).
 */
export default class extends Controller {
    interval;

    static values = {
        url: String
    }

    connect() {
        const self = this;

        this.interval = setInterval(function() {
            self.checkStatus();
        }, 1000);
    }

    disconnect() {
        clearInterval(this.interval);
    }

    async checkStatus() {
        let response = await fetch(this.urlValue, {
            method: 'GET',
        });

        if (response.status !== 200) {
            throw new Error('Не удалось обновить статус загрузки');
        }

        response = await response.json();

        switch (response.status) {
            case 'in_progress':
                break;
            default:
                clearInterval(this.interval);
                document.location.reload();
                break;
        }
    }
}