import {Controller} from "@hotwired/stimulus";
import Preloader from '../../components/preloader';

/**
 * Обработчик обновления блока с комиссиями на странице редактирования листа (под табличным редактором).
 */
export default class extends Controller {
    static values = {
        refreshUrl: String
    }

    refresh() {
        Preloader.create();

        $.ajax({
            type: "GET",
            url: this.refreshUrlValue,
            success: function (data) {
                Turbo.renderStreamMessage(data);

                Preloader.remove();
            },
            error: function() {
                Preloader.remove();
            }
        })
    }
}