import {Controller} from "@hotwired/stimulus";
import {getPluginsDefaults, getRenderDefaults, onItemAddDefault} from "../../components/tomselect2";
import TomSelect from "tom-select";

/**
 * Обработчик формы настроек листа.
 */
export default class extends Controller
{
    static get targets() {
        return [
            'channel',
            'gmvRatioRow'
        ]
    }

    connect() {
        this.channel = new TomSelect(this.channelTarget, {
            render: getRenderDefaults(),
            onItemAdd: onItemAddDefault(),
            plugins: getPluginsDefaults(),
        });

        this.channel.on('change', (value) => {
            const label = this.channelTarget.querySelector('option[value="'+value+'"]').innerText;
            this.gmvRatioRowTarget.classList.add('d-none');

            if (label === 'Маркетплейсы') {
                this.gmvRatioRowTarget.classList.remove('d-none');
            }
        })
    }

    disconnect() {
        this.channel.destroy();
    }
}