import {Controller} from "@hotwired/stimulus";
import interact from "interactjs";
import Cookies from "js-cookie";

/**
 * Обработчик изменений размеров табличного редактора в листе.
 */
export default class extends Controller {
    static values = {
        cookieId: String
    }

    connect() {
        const self = this;

        interact(this.element)
            .draggable({
                onmove: window.dragMoveListener
            })
            .resizable({
                edges: {left: false, right: false, bottom: true, top: false},
                listeners: {
                    move: function (event) {
                        var target = event.target,
                            x = (parseFloat(target.getAttribute('data-x')) || 0),
                            y = (parseFloat(target.getAttribute('data-y')) || 0);

                        target.style.width  = event.rect.width + 'px';
                        target.style.height = event.rect.height + 'px';

                        x += event.deltaRect.left;
                        y += event.deltaRect.top;

                        target.style.webkitTransform = target.style.transform =
                            'translate(' + x + 'px,' + y + 'px)';

                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);

                        Cookies.set(self.cookieIdValue, target.style.height);

                        luckysheet.resize();
                    },
                    end: function() {
                        luckysheet.resize();
                    }
                }
            });
    }
}