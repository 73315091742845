import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";
import {getPluginsDefaults} from "../../components/tomselect2";

/**
 * Обработчик множественного выбора метрик в листах, копирование и вставка списка метрик из буфера обмена.
 */
export default class extends Controller {
    select;

    static get targets() {
        return ['select', 'copyButton', 'clipboardBuffer', 'copyButtonBottom'];
    }

    connect() {
        const _this = this;

        this.select = new TomSelect(this.selectTarget, {
            persist: false,
            create: false,
            plugins: getPluginsDefaults(),
            render:{
                option: function(data) {
                    const div = document.createElement('div');

                    const spanTitle = document.createElement('span');
                    spanTitle.className = 'title';
                    spanTitle.innerText = data.text;
                    div.append(spanTitle);

                    if (data.subtitle) {
                        const spanGroup = document.createElement('span');
                        spanGroup.className = 'subtitle';
                        spanGroup.innerText = data.subtitle;
                        div.append(spanGroup);
                    }

                    return div;
                },
            },
            onItemAdd: (value, item) => {
                this.select.setTextboxValue('');

                let depends = $(this.selectTarget).find('option[value='+value+']').attr('data-dependencies');
                if (!depends) {
                    return;
                }

                depends = depends.split(',');
                for (const i in depends) {
                    _this.select.addItem(depends[i]);
                }
            },
            onChange: () => {
                if (false === this.hasCopyButtonTarget) {
                    return;
                }

                if (this.select.getValue().length > 0) {
                    this.copyButtonTarget.classList.remove('disabled');
                } else {
                    this.copyButtonTarget.classList.add('disabled');
                }
            }
        });

        const input = document.getElementById(this.selectTarget.id+'-ts-control');
        if (input) {
            this.pasteEventHandler(input);
        }
    }

    copy() {
        let result = [];

        const values = this.select.getValue();

        for (const i in values) {
            const id = values[i];
            result.push(this.select.options[id].text);
        }

        this.doCopy(result.join("\t"), this.copyButtonTarget.querySelector('i'));
    }

    copyBottom() {
        let result = [];

        $('.del_metric_checkbox').each(function () {
            if ($(this).prop('checked')) {
                result.push($(this).attr('data-name'));
            }
        });

        this.doCopy(result.join("\t"), this.copyButtonBottomTarget.querySelector('i'))
    }

    doCopy(message, icon) {
        const element = this.clipboardBufferTarget;
        element.classList.remove('d-none');
        element.value = message;
        element.select();
        element.setSelectionRange(0, 99999);
        document.execCommand('copy');
        element.classList.add('d-none');
        element.value = '';

        icon.classList.remove('fa-copy');
        icon.classList.add('fa-check');

        setTimeout(() => {
            icon.classList.remove('fa-check');
            icon.classList.add('fa-copy');
        }, 500);
    }

    pasteEventHandler(input) {
        const _this = this;

        input.addEventListener('paste', function (event) {
            event.preventDefault();

            const paste = (event.clipboardData || window.clipboardData).getData("text");
            const parts = paste.split("\t");

            for (const i in parts) {
                for (const id in _this.select.options) {
                    const searchValue = parts[i].trim();
                    if (searchValue.startsWith(_this.select.options[id].text)) {
                        _this.select.addItem(id);
                        break;
                    }
                }
            }
        });
    }

    disconnect() {
        this.select.destroy();
    }
}