/**
 * Переводит окно в полноэкранный режим.
 *
 * @param element какой элемент отобразить в полноэкранном режиме
 */
export function requestFullScreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
    }
}